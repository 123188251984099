import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, radius } from 'theme'
import Modal from 'components/Modal'
import { Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import clear from '../../static/svg/clear.svg'

const IconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${colors.white};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoModal = ({ isOpen, handleDismiss }) => (
  <Modal isOpen={isOpen} handleDismiss={handleDismiss} maxwidth="880px">
    <Box maxWidth="900px" height="0" pb="56.25%" position="relative">
      <StyledIframe
        title="Agency Vista is the largest network of marketing agencies where businesses can discover, review, and connect
            with marketing agencies. Businesses have the confidence in knowing that they are working with trusted and
            reliable agencies, limiting wasted time and resources."
        src="https://www.youtube.com/embed/IlHgtszXS6o?autoplay=1"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
    <IconWrapper onClick={handleDismiss}>
      <LocalImage width="10px" height="10px" src={clear} />
    </IconWrapper>
  </Modal>
)

VideoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
}

export default VideoModal
