import React, { useState } from 'react'
import styled from 'styled-components'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H2, H3, Text } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import BgImg from 'components/BgImage'
import Button from 'components/Button'
import Link from 'components/Link'
import Hero from 'components/Hero'
import AgenciesCounter from 'components/AgenciesCounter'
import aboutAgencyVista3 from '../../static/images/about_agencyvista3.jpg'
import aboutAgencyVista4 from '../../static/images/about_agencyvista4.png'
import aboutAgencyVista5 from '../../static/images/about_agencyvista5.jpg'
import AgencyIcon from '../../static/images/Agency_Icon.png'
import BusinessIcon from '../../static/images/Business_Icon.png'
import ImageText from './ImageText'
import VideoModal from './VideoModal'

const MOCK_CONTENT = [
  {
    title: 'Everyone’s a Marketing Expert Now — We’re Here to Weed the Garden',
    body:
      'Here’s the deal: not everyone is a marketing genius. There are tens of thousands of social media agencies on the market. But how do you sort through all the nonsense and really find people who can provide value for you?',
    image: aboutAgencyVista5,
    button: 'Why Trust Our Agencies',
    buttonLink: '/trust',
  },
  {
    title: 'Agency Vista plays a crucial role in garnering trust and credibility in your buying decision',
    body:
      'We’re changing the way businesses deals get done. You’ve seen their rehearsed pitches, shiny sales decks, and well-crafted case studies - now is the time to gain access to key agency information that will help you choose the best partner for your business.',
    image: aboutAgencyVista4,
    button: 'Learn about agency verification process',
    buttonLink: '/badge',
  },
]

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const ButtonLink = styled(Button.Primary)`
  display: flex;
  text-decoration: none;
`

const About = () => {
  const [isOpenVideo, setIsOpenVideo] = useState(false)
  return (
    <App>
      <SEO title="About" description="Scale your business with a trusted and verified agency" path="/about/" />
      <Hero>
        <BgImg src={aboutAgencyVista3} alt="Scale your business with a trusted and verified agency" />
        <H1 color="white">Scale your business with a trusted and verified agency</H1>
        <Box mt="m" maxWidth="900px">
          <StyledH3 color="white">
            Agency Vista is a network of <AgenciesCounter fontWeight="bold" fontSize="l" as="span" /> marketing
            agencies. Businesses can discover, review, and connect with marketing agencies and have the confidence in
            knowing that they are working with trusted and reliable agencies, limiting wasted time and resources.
          </StyledH3>
        </Box>
        <Box mt="m">
          <ButtonLink>
            <Text
              fontWeight="medium"
              onClick={() => {
                setIsOpenVideo(true)
              }}
            >
              Watch Video Introduction
            </Text>
          </ButtonLink>
        </Box>
      </Hero>
      <Container maxWidth="1080px">
        <Box mt="xxl">
          {MOCK_CONTENT.map((content, index) => (
            <Box key={content.title} mb="xxl">
              <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index !== 1} {...content} />
            </Box>
          ))}
        </Box>
        <Flex mt="xxxl" flexDirection="column" px="m">
          <H1>Who do we help?</H1>
          <Text color="ternaryText" fontSize="l">
            Any business or organization that is looking to partner with a marketing agency.
          </Text>
        </Flex>
        <Grid px="m" mt="l" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="m" backgroundColor="none">
          <Flex flexDirection="column" backgroundColor="#ffffff" px="m" pt="l" pb="xl" position="relative">
            <LocalImage width="60px" height="60px" src={BusinessIcon} />
            <H2 my="m">Businesses</H2>
            <Text color="ternaryText" fontSize="l" mb="m">
              Connect with verified agencies that are best suited to your needs.{' '}
              <AgenciesCounter fontSize="l" as="span" color="ternaryText" /> agencies with real user reviews to help you
              make better buying decisions.
            </Text>
            <Box position="absolute" bottom="20px">
              <Button.Primary as={Link} href="/find-agency" fontSize="s">
                Find an Agency
              </Button.Primary>
            </Box>
          </Flex>
          <Flex flexDirection="column" backgroundColor="#ffffff" px="m" pt="l" pb="xl" position="relative">
            <LocalImage width="60px" height="60px" src={AgencyIcon} />
            <H2 my="m">Agencies</H2>
            <Text color="ternaryText" fontSize="l" mb="m">
              Generate more qualified leads by connecting with organizations that are actively searching for your
              solutions. Receive 10+ supercharged for SEO pages complimentary to your profile!
            </Text>
            <Box position="absolute" bottom="20px">
              <Button.Primary as={Link} href="/signup" fontSize="s">
                Claim Your Profile
              </Button.Primary>
            </Box>
          </Flex>
        </Grid>
        <VideoModal
          isOpen={isOpenVideo}
          handleDismiss={() => {
            setIsOpenVideo(false)
          }}
        />
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

export default About
